import { AES, enc } from "crypto-js";
import { isEmpty, isObject } from "lodash";
import {
	saveData,
	deleteData,
	getData,
	saveRawData,
	getRawData,
} from "@/core/services/local.service";

const ID_TOKEN_KEY = "access_token";
const ID_TOKEN_TYPE = "token_type";
const ID_TOKEN_EXPIRY = "expiry";
const ID_TOKEN_EXPIRY_AT = "expires_at";
const ID_CURRENT_USER = "_btusr_tmp";
const ID_MENU_LIST = "_btcsr_tmp";
const ID_PERMISSION_LIST = "_btpsr_tmp";
const ID_CLIENT_DETAILS = "_btcld_tmp";
const ENCRYPTION_KEY = process.env.VUE_APP_ENCRYPTION_KEY;

const destroyAuth = () => {
	deleteData(ID_TOKEN_KEY);
	deleteData(ID_TOKEN_TYPE);
	deleteData(ID_TOKEN_EXPIRY);
	deleteData(ID_TOKEN_EXPIRY_AT);
	deleteData(ID_CURRENT_USER);
	deleteData(ID_MENU_LIST);
	deleteData(ID_PERMISSION_LIST);
	deleteData(ID_CLIENT_DETAILS);
};

export const saveLocalStorage = (key, data) => {
	try {
		if (isEmpty(data) === false && isObject(data)) {
			let encrypted = AES.encrypt(JSON.stringify(data), ENCRYPTION_KEY).toString();
			saveData(key, encrypted);
		}
	} catch (error) {
		console.warn({ error });
	}
};

export const getLocalStorage = (key) => {
	try {
		let encrypted = getData(key);
		if (!encrypted) {
			return null;
		}
		let decrypted = AES.decrypt(encrypted, ENCRYPTION_KEY);
		return JSON.parse(decrypted.toString(enc.Utf8));
	} catch (error) {
		console.warn({ error });
	}
};

export const isAuthenticated = () => {
	return !!getRawData(ID_TOKEN_KEY);
};

export const getToken = () => {
	return getRawData(ID_TOKEN_KEY);
};

export const getTokenType = () => {
	return getRawData(ID_TOKEN_TYPE);
};

export const getTokenExpiry = () => {
	return getRawData(ID_TOKEN_EXPIRY);
};

export const getCurrentUser = () => {
	return getLocalStorage(ID_CURRENT_USER);
};

export const saveToken = ({ user, type, expiry, token }) => {
	saveRawData(ID_TOKEN_TYPE, type);
	saveRawData(ID_TOKEN_KEY, token);
	saveRawData(ID_TOKEN_EXPIRY, expiry);
	saveLocalStorage(ID_CURRENT_USER, user);
};

export const destroyToken = () => {
	destroyAuth();
};

export default {
	getToken,
	getTokenType,
	getTokenExpiry,
	getCurrentUser,
	saveToken,
	saveLocalStorage,
	getLocalStorage,
	isAuthenticated,
	destroyToken,
};
