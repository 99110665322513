import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			redirect: "/clients",
			component: () => import("@/view/layout/Layout"),
			children: [
				{
					path: "clients",
					name: "clients",
					component: () => import("@/view/pages/client/Users.vue"),
				},
				{
					path: "clients-detail/:uuid",
					name: "clients-detail",
					component: () => import("@/view/pages/client/UserDetail.vue"),
				}
			],
		},
		{
			path: "/custom-error",
			name: "error",
			component: () => import("@/view/pages/error/Error"),
			children: [
				{
					path: "error-1",
					name: "error-1",
					component: () => import("@/view/pages/error/Error-1"),
				},
			],
		},
		{
			name: "authenticate",
			path: "/",
			component: () => import("@/view/pages/auth/Authenticate"),
			children: [
				{
					path: "login",
					name: "login",
					component: () => import("@/view/pages/auth/Login"),
				}
			],
		},
		{
			path: "*",
			redirect: "/404",
		},
		{
			// the 404 route, when none of the above matches
			path: "/404",
			name: "404",
			component: () => import("@/view/pages/error/Error-1"),
		},
	],
});
