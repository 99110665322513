// import ApiService from '@/core/services/api.service';
import { toSafeInteger, isArray } from "lodash";

// action types

// mutation types
export const ERROR = "error";
export const MESSAGE = "message";
export const SEARCH_ENABLED = "searchEnabled";
export const SEARCH_TITLE = "searchTitle";
export const SET_ERROR = "setError";
export const SET_MESSAGE = "setMessage";
export const SET_SEARCH_ENABLED = "setSearchEnabled";
export const SET_SEARCH_TITLE = "setSearchTitle";
export const SET_UPLOAD_STATUS = "setUploadStatus";

const state = {
	errors: [],
	messages: [],
	search_enabled: false,
	search_title: null,
	upload_status: false,
};

const getters = {
	errors(state) {
		return state.errors;
	},
	canUpload(state) {
		return state.upload_status;
	},
	isUploadDisabled(state) {
		return !state.upload_status;
	},
	messages(state) {
		return state.messages;
	},
	searchEnabled(state) {
		return state.search_enabled;
	},
	searchTitle(state) {
		return state.search_title;
	},
};

const actions = {
	[SET_UPLOAD_STATUS](context, param) {
		context.commit(SET_UPLOAD_STATUS, param);
	},
	[ERROR](context, param) {
		context.commit(SET_ERROR, param);
	},
	[MESSAGE](context, param) {
		context.commit(SET_MESSAGE, param);
	},
	[SEARCH_ENABLED](context, param) {
		context.commit(SET_SEARCH_ENABLED, param);
	},
	[SEARCH_TITLE](context, param) {
		context.commit(SET_SEARCH_TITLE, param);
	},
};

const mutations = {
	[SET_UPLOAD_STATUS](state, status) {
		state.upload_status = status;
	},
	[SET_ERROR](state, error) {
		if (error && isArray(error)) {
			state.errors = error.map((row) => {
				row.timeout = toSafeInteger(process.env.VUE_APP_MESSAGE_TIMEOUT);
				return row;
			});
		} else {
			state.errors = [];
		}
	},
	[SET_MESSAGE](state, message) {
		if (message && isArray(message)) {
			state.messages = message.map((row) => {
				row.timeout = toSafeInteger(process.env.VUE_APP_MESSAGE_TIMEOUT);
				return row;
			});
		} else {
			state.messages = [];
		}
	},
	[SET_SEARCH_ENABLED](state, status) {
		state.search_enabled = status;
	},
	[SET_SEARCH_TITLE](state, title) {
		state.search_title = title;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
